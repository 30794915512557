<template>
    <modal ref="modalCrearDelivery" titulo="Editar Delivery" icon="moped" adicional="Editar" :cargado-modal="cargando" no-aceptar tamano="modal-lg" @adicional="editarDelivery">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-auto px-4" />
                <div class="col">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <slim-cropper ref="foto_cedis" :options="slimOptions" class="border" style="height:150px;background:#F8F9FF;width:150px;border-radius:12px;" />
                        </div>
                        <div class="col-8">
                            <p class="text-general f-15 pl-3">
                                Nombre
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:25" name="nombre">
                                <el-input v-model="model.nombre" maxlength="25" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                            <p class="text-general f-15 pl-3 mt-3">
                                Documento de identidad
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|numeric|max:20" name="documento de identidad">
                                <el-input v-model="model.documento_identidad" maxlength="20" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col ">
                            <p class="text-general f-15">
                                Seleccione el tipo de Vehículo
                            </p>
                            <div>
                                <i
                                class="mx-2 cr-pointer icon-moped"
                                :class="`${model.aplica_moto ? 'text-general-red' : 'text-general2'} f-30`"
                                @click="model.aplica_moto = !model.aplica_moto"
                                />
                                <i
                                class="mx-2 cr-pointer icon-car"
                                :class="`${model.aplica_carro ? 'text-general-red' : 'text-general2'} f-30`"
                                @click="model.aplica_carro = !model.aplica_carro"
                                />
                                <i
                                class="mx-2 cr-pointer icon-van"
                                :class="`${model.aplica_camioneta ? 'text-general-red' : 'text-general2'} f-30`"
                                @click="model.aplica_camioneta = !model.aplica_camioneta"
                                />
                                <i
                                class="mx-2 cr-pointer icon-truck-outline"
                                :class="`${model.aplica_camion ? 'text-general-red' : 'text-general2'} f-30`"
                                @click="model.aplica_camion = !model.aplica_camion"
                                />
                            </div>
                            <span v-if="validarVehivulo" class="text-danger w-100 f-11"> Este campo es requerido </span>
                        </div>

                        <div class="col">
                            <p class="text-general f-15">
                                Vigencia permiso de conducción
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="fecha vigencia">
                                <el-date-picker v-model="model.fecha_conducir" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 my-4">
                        <div class="col">
                            <p class="text-general f-15">
                                Fecha de vinculación
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="fecha vinculación">
                                <el-date-picker v-model="model.fecha_vinculacion" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" :picker-options="pickerOptions" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="text-general f-15">
                                Fecha de nacimiento
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="fecha nacimiento">
                                <el-date-picker v-model="model.fecha_nacimiento" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" :picker-options="pickerOptions" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col">
                            <p class="text-general pl-2 f-15">
                                Añadir cedis asociado
                            </p>
                            <el-select v-model="value" filterable class="w-100" @change="agregarCedis">
                                <el-option
                                v-for="item in cedisNoSeleccionados"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span v-if="validarCedis" class="text-danger w-100 f-11"> Este campo es requerido </span>
                        </div>
                        <div class="col pt-4">
                            <div v-for="(data, d) in cedisSeleccionados" :key="d" class="row mx-0 align-items-center">
                                <div class="col-auto d-middle">
                                    <i
                                    class="f-20 cr-pointer"
                                    :class="`${data.principal ? 'icon-star text-general-red' : 'icon-star-empty text-general'}`"
                                    @click="clicks(data)"
                                    />
                                </div>
                                <div class="col px-0 d-middle text-general f-15">
                                    {{ data.nombre }}
                                </div>
                                <div class="col-auto d-middle">
                                    <i class="icon-cancel-circled f-20 cr-pointer" @click="eliminarCedi(d)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col">
                            <p class="text-general pl-2 f-15">
                                Número telefónico
                            </p>
                            <ValidationProvider v-slot="{errors}" :rules="{required: true, max:10, numeric: true}" name="número telefónico">
                                <el-input v-model="model.telefono" maxlength="10" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="text-general pl-2 f-15">
                                Correo electrónico
                            </p>
                            <ValidationProvider v-slot="{ errors, failedRules }" rules="required|email|max:70" name="correo electrónico">
                                <el-input v-model="model.correo" type="email" maxlength="70">
                                    <i v-if="failedRules.email" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                    <i v-else-if="model.correo != ''" slot="suffix" class="icon-ok-circled-outline text-success el-input__icon f-17" />
                                </el-input>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col">
                            <p class="f-15 pl-2 text-general">
                                Contraseña
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:200|min:4|max:20" name="contraseña" vid="confirmation">
                                <el-input v-model="model.password" class="w-100" type="password" maxlength="201" @change="cambioPassword" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="f-15 pl-2 text-general">
                                Repetir Contraseña
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:200|confirmed:confirmation" name="confirmar contraseña">
                                <el-input v-model="model.password_confirmation" class="w-100" type="password" maxlength="201" @change="cambioPassword" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-auto px-4" />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Delivery from "~/services/delivery/index";
export default {
    data(){
        return {
            input: '',
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > Date.now()
                },
            },
            value: null,
            cargando: false,
            validarVehivulo: false,
            validarCedis: false,
            cambioPass: 0,
            model: {
                nombre: null,
                foto: null,
                id_cedis: null,
                idUser: null,
                id: null,
                fecha_nacimiento: null,
                fecha_vinculacion: null,
                fecha_conducir: null,
                aplica_moto: null,
                aplica_carro: null,
                aplica_camioneta: null,
                aplica_camion: null,
                documento_identidad: null,
                id_user: null,
                created_by: null,
                updated_by: null,
                created_at: null,
            },
            ids_cedis: [],
            cedisSeleccionados: [],
        }
    },
    computed: {
        ...mapGetters({
            cedis:'cedis/cedis',
        }),
        cedisCopy(){
            return this.cedis.map(el => ({...el, principal: false}))
        },
        cedisNoSeleccionados(){
            return this.cedisCopy.filter(el => !this.ids_cedis.includes(el.id) && el.id != 0)
        },
        idDelivery(){
            return this.$route.params.id_delivery
        },
        validateTransport(){
            return this.model.aplica_moto || this.model.aplica_carro || this.model.aplica_camioneta || this.model.aplica_camion
        },
    },
    watch: {
        validateTransport(val){
            console.log({val});
            this.validarVehivulo = !val
        },
        cedisSeleccionados(val){
            console.log({val});
            this.validarCedis = !val.length
        },
    },
    methods: {
        toggle(data){
            let datos = _.cloneDeep(data)
            this.model = datos
            this.ids_cedis = datos.ids_cedis
            this.cedisSeleccionados = datos.cedis
            if (datos.foto !== null){
                this.$refs.foto_cedis.set_image(`${datos.imagen}`)
            } else {
                this.$refs.foto_cedis.instanciaCrop.remove()
            }
            this.model.password = '*********'
            this.model.password_confirmation = '*********'
            this.$refs.modalCrearDelivery.toggle();
        },
        clicks(cedi){
            this.cedisSeleccionados.map(el => el.principal = el.id == cedi.id)
            this.model.id_cedis = cedi.id
        },
        eliminarCedi(id){
            const spliced = this.cedisSeleccionados.splice(id,1)
            this.ids_cedis.splice(id,1)
            if (spliced[0].principal && this.cedisSeleccionados.length) this.cedisSeleccionados[0].principal = true
        },
        async editarDelivery(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!this.validateTransport) this.validarVehivulo = true
                if(!this.cedisSeleccionados.length) this.validarCedis = true
                if(!valid || !this.cedisSeleccionados.length || !this.validateTransport){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.foto_cedis.instanciaCrop.dataBase64.output
                this.model.foto = archivo.image
                this.model.cambioPass = this.cambioPass
                this.model.user_cedis = this.ids_cedis
                this.cargando = true

                const {data} = await Delivery.editarDelivery(this.idDelivery,this.model)
                this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                this.$emit('actualizar')
                this.limpiar()
                this.$refs.modalCrearDelivery.toggle()
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        limpiar(){
            this.model = {
                nombre: null,
                foto: null,
                id_cedis: null,
                idUser: null,
                id: null,
                fecha_nacimiento: null,
                fecha_vinculacion: null,
                fecha_conducir: null,
                aplica_moto: null,
                aplica_carro: null,
                aplica_camioneta: null,
                aplica_camion: null,
                documento_identidad: null,
                id_user: null,
                created_by: null,
                updated_by: null,
                created_at: null,
            }
            this.$refs.validacion.reset()
            this.$refs.foto_cedis.instanciaCrop.remove()
        },
        cambioPassword(){
            this.cambioPass = 1
        },
        agregarCedis(val){
            let cedi = this.cedisCopy.find(el => el.id == val)
            this.ids_cedis.push(val)
            this.model.id_cedis = this.cedisSeleccionados.length ? this.model.id_cedis : cedi.id
            cedi.principal = this.cedisSeleccionados.length ? false : true
            this.cedisSeleccionados.push(cedi)
            this.value = null
        }
    }
}
</script>
<style lang="css" scoped>
.text-blue{
    color: #02D6BC;
}
</style>
