var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalCrearDelivery",attrs:{"titulo":"Editar Delivery","icon":"moped","adicional":"Editar","cargado-modal":_vm.cargando,"no-aceptar":"","tamano":"modal-lg"},on:{"adicional":_vm.editarDelivery}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-auto px-4"}),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto"},[_c('slim-cropper',{ref:"foto_cedis",staticClass:"border",staticStyle:{"height":"150px","background":"#F8F9FF","width":"150px","border-radius":"12px"},attrs:{"options":_vm.slimOptions}})],1),_c('div',{staticClass:"col-8"},[_c('p',{staticClass:"text-general f-15 pl-3"},[_vm._v(" Nombre ")]),_c('ValidationProvider',{attrs:{"rules":"required|max:25","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"25"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('p',{staticClass:"text-general f-15 pl-3 mt-3"},[_vm._v(" Documento de identidad ")]),_c('ValidationProvider',{attrs:{"rules":"required|numeric|max:20","name":"documento de identidad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"20"},model:{value:(_vm.model.documento_identidad),callback:function ($$v) {_vm.$set(_vm.model, "documento_identidad", $$v)},expression:"model.documento_identidad"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 mt-4"},[_c('div',{staticClass:"col "},[_c('p',{staticClass:"text-general f-15"},[_vm._v(" Seleccione el tipo de Vehículo ")]),_c('div',[_c('i',{staticClass:"mx-2 cr-pointer icon-moped",class:((_vm.model.aplica_moto ? 'text-general-red' : 'text-general2') + " f-30"),on:{"click":function($event){_vm.model.aplica_moto = !_vm.model.aplica_moto}}}),_c('i',{staticClass:"mx-2 cr-pointer icon-car",class:((_vm.model.aplica_carro ? 'text-general-red' : 'text-general2') + " f-30"),on:{"click":function($event){_vm.model.aplica_carro = !_vm.model.aplica_carro}}}),_c('i',{staticClass:"mx-2 cr-pointer icon-van",class:((_vm.model.aplica_camioneta ? 'text-general-red' : 'text-general2') + " f-30"),on:{"click":function($event){_vm.model.aplica_camioneta = !_vm.model.aplica_camioneta}}}),_c('i',{staticClass:"mx-2 cr-pointer icon-truck-outline",class:((_vm.model.aplica_camion ? 'text-general-red' : 'text-general2') + " f-30"),on:{"click":function($event){_vm.model.aplica_camion = !_vm.model.aplica_camion}}})]),(_vm.validarVehivulo)?_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" Este campo es requerido ")]):_vm._e()]),_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-15"},[_vm._v(" Vigencia permiso de conducción ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"fecha vigencia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","format":"dd - MM - yyyy","value-format":"yyyy-MM-dd"},model:{value:(_vm.model.fecha_conducir),callback:function ($$v) {_vm.$set(_vm.model, "fecha_conducir", $$v)},expression:"model.fecha_conducir"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 my-4"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-15"},[_vm._v(" Fecha de vinculación ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"fecha vinculación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","format":"dd - MM - yyyy","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},model:{value:(_vm.model.fecha_vinculacion),callback:function ($$v) {_vm.$set(_vm.model, "fecha_vinculacion", $$v)},expression:"model.fecha_vinculacion"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-15"},[_vm._v(" Fecha de nacimiento ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"fecha nacimiento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","format":"dd - MM - yyyy","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},model:{value:(_vm.model.fecha_nacimiento),callback:function ($$v) {_vm.$set(_vm.model, "fecha_nacimiento", $$v)},expression:"model.fecha_nacimiento"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general pl-2 f-15"},[_vm._v(" Añadir cedis asociado ")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":""},on:{"change":_vm.agregarCedis},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.cedisNoSeleccionados),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),(_vm.validarCedis)?_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" Este campo es requerido ")]):_vm._e()],1),_c('div',{staticClass:"col pt-4"},_vm._l((_vm.cedisSeleccionados),function(data,d){return _c('div',{key:d,staticClass:"row mx-0 align-items-center"},[_c('div',{staticClass:"col-auto d-middle"},[_c('i',{staticClass:"f-20 cr-pointer",class:("" + (data.principal ? 'icon-star text-general-red' : 'icon-star-empty text-general')),on:{"click":function($event){return _vm.clicks(data)}}})]),_c('div',{staticClass:"col px-0 d-middle text-general f-15"},[_vm._v(" "+_vm._s(data.nombre)+" ")]),_c('div',{staticClass:"col-auto d-middle"},[_c('i',{staticClass:"icon-cancel-circled f-20 cr-pointer",on:{"click":function($event){return _vm.eliminarCedi(d)}}})])])}),0)]),_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general pl-2 f-15"},[_vm._v(" Número telefónico ")]),_c('ValidationProvider',{attrs:{"rules":{required: true, max:10, numeric: true},"name":"número telefónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"maxlength":"10"},model:{value:(_vm.model.telefono),callback:function ($$v) {_vm.$set(_vm.model, "telefono", $$v)},expression:"model.telefono"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general pl-2 f-15"},[_vm._v(" Correo electrónico ")]),_c('ValidationProvider',{attrs:{"rules":"required|email|max:70","name":"correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('el-input',{attrs:{"type":"email","maxlength":"70"},model:{value:(_vm.model.correo),callback:function ($$v) {_vm.$set(_vm.model, "correo", $$v)},expression:"model.correo"}},[(failedRules.email)?_c('i',{staticClass:"icon-attention-alt text-danger el-input__icon f-17",attrs:{"slot":"suffix"},slot:"suffix"}):(_vm.model.correo != '')?_c('i',{staticClass:"icon-ok-circled-outline text-success el-input__icon f-17",attrs:{"slot":"suffix"},slot:"suffix"}):_vm._e()]),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"f-15 pl-2 text-general"},[_vm._v(" Contraseña ")]),_c('ValidationProvider',{attrs:{"rules":"required|max:200|min:4|max:20","name":"contraseña","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"type":"password","maxlength":"201"},on:{"change":_vm.cambioPassword},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"f-15 pl-2 text-general"},[_vm._v(" Repetir Contraseña ")]),_c('ValidationProvider',{attrs:{"rules":"required|max:200|confirmed:confirmation","name":"confirmar contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"type":"password","maxlength":"201"},on:{"change":_vm.cambioPassword},model:{value:(_vm.model.password_confirmation),callback:function ($$v) {_vm.$set(_vm.model, "password_confirmation", $$v)},expression:"model.password_confirmation"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])]),_c('div',{staticClass:"col-auto px-4"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }